import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// import View from "../../../Assets/Icons/View.svg";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
import AdvancePrintVoucher from "./AdvancePrintVoucher";
// import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import dayjs from "dayjs";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";

function CreateAdvance() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  const [existingAdvanceDetails, setExistingAdvanceDetails] = useState([]);
  const [fileType, setFileType] = useState("");
  const [advanceDetails, setAdvanceDetails] = useState({
    employee_id: [],
    type: "advance",
    employee_name: "",
    designation: "",
    department: "",
    selecttype: "cash",
    amount: "",
    duration: "",
    finance_date: dayjs(new Date()).format("YYYY-MM-DD"),
    approved_by: "",
    file: "",
    remarks: ""
  });
  const [errors, seterrors] = useState({});
  const [pfComplaint, setPfComplaint] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [designationList, setDesignationList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [designationName, setdesignationName] = useState("");
  const [departmentName, setdepartmentName] = useState("");
  const [branchName, setbranchName] = useState("");
  const [enableExisting, setEnableExisting] = useState(true);

  const [loanList, setLoanList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [approvedList, setApprovalList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [printVoucherData, setPrintVoucherData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isErrors, setIsErrors] = useState({ employee_id: "" });

  const [salary, setSalary] = useState("");
  const [advanceRemark, setAdvanceRemark] = useState("");

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    getDesignationList();
    getDepartmentList();
    getBranchList();
    getLoanList();
    getEmployeeList();
  }, []);

  const getDesignationList = () => {
    setLoading(true);
    GET_API("/designationList")
      .then(async function (data) {
        setLoading(false);
        setDesignationList(data?.data[0]);
      })
      .catch((error) => {
        setLoading(false);
      });
  };


  const handleProfileImage = (e) => {
    const type = e?.target?.files[0]?.type?.split("/").shift();
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setAdvanceDetails({ ...advanceDetails, file: fileReader?.result });
        setFileType(type);
      }
    };
    fileReader?.readAsDataURL(e?.target?.files[0]);
  };

  const getDepartmentList = () => {
    setLoading(true);
    GET_API("/DepartmentList")
      .then(async function (data) {
        setDepartmentList(data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getBranchList = async () => {
    setLoading(true);
    GET_API("/branchList")
      .then(async function (data) {
        setbranchList(data?.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getLoanList = async () => {
    setLoading(true);
    GET_API("/FinanceCategoriesList/loan")
      .then((response) => {
        const list = response?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        list.unshift({ value: "", label: "Select..." });
        setLoanList(list);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");

      if (employeeList?.data?.status === 200) {
        const res = employeeList?.data?.data.map((emp, i) => {
          return {
            label: `${emp?.first_name} ${
              emp?.last_name ? emp?.last_name : ""
            } (${emp?.emp_code})`,
            value: emp?.id,
            salary: emp?.basic_salary,
          };
        });
        const appList = employeeList?.data?.data.map((emp, i) => {
          return {
            label: `${emp?.first_name} ${
              emp?.last_name ? emp?.last_name : ""
            } (${emp?.emp_code})`,
            value: emp?.id,
            salary: emp?.basic_salary,
          };
        });
        setEmployeeList(res);
        setApprovalList(appList);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to get employee list...");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setAdvanceDetails(() => ({ ...advanceDetails, [name]: value }));
  };

  const handleSelectChange = (e, name) => {
    setAdvanceDetails(() => ({ ...advanceDetails, [name]: e?.value }));
  };

  // const printVoucher = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  // useEffect(() => {
  //   if (isPrintVoucher) {
  //     printVoucher();
  //     navigate("/expense/advance");
  //   }
  // }, [isPrintVoucher, printVoucher]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (advanceDetails.employee_id.length === 0) {
      newErrors.employee_id = "Employee Name is required";
      valid = false;
      setButtonDisabled(false);
    }
    setIsErrors(newErrors);
    return valid;
  };

  const createAdvance = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateAdvance(advanceDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    if (validateForm()) {
      const userId = localStorage.getItem("user_id");
      POST_API("/addFinanceDetails", { ...advanceDetails, created_by: userId })
        .then(async function (data) {
          alertify.success(data?.data?.message);
          navigate("/expense/advance");
          // setPrintVoucherData(data?.data?.data);
          // if (selectedOptions?.length <= 1) {
          //   setTimeout(() => {
          //     setButtonDisabled(false);
          //     printVoucher();
          //     setIsPrintVoucher(true);
          //   }, 300);
          // } else {
          //   navigate("/advance");
          // }
        })

        .catch((error) => {
          alertify.error(error?.message);
          setButtonDisabled(false);
        });
    }
  };

  const resetForm = () => {
    setdesignationName("");
    setdepartmentName("");
    setbranchName("");
    setSelectedOptions([]);
    setAdvanceDetails({
      employee_id: [],
      type: "advance",
      employee_name: "",
      designation: "",
      department: "",
      selecttype: "",
      finance_category_id: "",
      amount: "",
      duration: "",
      approved_by: "",
      remarks:""
    });
  };

  if (loading) {
    return <Spinner />;
  }

  const checkExisting = async (emp) => {
    const empId = emp?.map((item) => item);
    setLoading(true);
    try {
      if (empId.length) {
        const existingAdvanceList = await POST_API(
          `checkExistingFinance/advance`,
          { employee_ids: empId }
        );

        // console.log(existingAdvanceList?.data?.count);

        if(existingAdvanceList?.data?.count >= 1) {
          alertify.error("Already advance has been provided to this employee for this month.");
          setLoading(false);
          
          setAdvanceDetails((prevDetails) => ({
            ...prevDetails,  
            remarks: "Advance count of this month is " + (existingAdvanceList?.data?.count)
          }));
          setAdvanceRemark("Advance count of this month is " + (existingAdvanceList?.data?.count));
        } else {
          setAdvanceDetails((prevDetails) => ({
            ...prevDetails,  
            remarks: "FIRST ADVANCE"
          }));
          setAdvanceRemark("FIRST ADVANCE");
        }

        if (existingAdvanceList?.status === 200) {
          setPfComplaint(existingAdvanceList?.data?.pf_complaint);
          setExistingAdvanceDetails(existingAdvanceList?.data?.data);
          setLoading(false);
        }
      }
    } catch (error) {
      alertify.error("Failed To get Existing Advance Details");
      setLoading(false);
    }
  };

  const handleEmployeeName = async (e) => {
    setSelectedOptions(e);
    setIsErrors({ ...isErrors, ["employee_id"]: "" });
    const id = [e?.value];
    const salary = [e?.salary];
    setAdvanceDetails(() => ({ ...advanceDetails, employee_id: id }));
    checkExisting(id);
    setSalary(salary);
  };

  const selectType = [
    { value: "", label: "Please Select" },
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
  ];

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <Link to="/expense/advance" className="in-active">
            Advance List
          </Link>
          <Link>{">"}</Link>
          <Link className="active">Add Advance</Link>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Advance</div>

          {isPrintVoucher && (
            <AdvancePrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )}

          {/***************Form************* **/}
          <form onSubmit={(e) => createAdvance(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  value={selectedOptions}
                  onChange={handleEmployeeName}
                  options={employeeList}
                />
                {isErrors["employee_id"] ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {isErrors["employee_id"]}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Advance Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  name="amount"
                  className="input-field"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={advanceDetails?.amount}
                  placeholder="Enter Amount"
                />
                {errors.amount ? (
                  <div className="validation-error-label">{errors.amount}</div>
                ) : null}
              </div>

              {/* <div className="field-block">
                <label className="label-name">Approval</label>
                <Select
                  options={approvedList}
                  value={approvedList?.find(
                    (option) => option?.value === advanceDetails?.approved_by
                  )}
                  onChange={(e) => handleSelectChange(e, "approved_by")}
                  name="approved_by"
                  className=""
                  isSearchable
                />
                {errors.approved_by ? (
                  <div className="validation-error-label">
                    {errors.approved_by}
                  </div>
                ) : null}
              </div> */}

              {pfComplaint != 0 ? (
                <div className="field-block">
                  <label className="label-name">
                    Payment Type<span className="required-symbol">*</span>
                  </label>
                  <Select
                    options={selectType}
                    value={selectType?.find(
                      (option) => option?.value === advanceDetails.selecttype
                    )}
                    onChange={(e) => handleSelectChange(e, "selecttype")}
                    name="selecttype"
                    className=""
                    isSearchable
                  />
                  {errors.selecttype ? (
                    <div className="validation-error-label">
                      {errors.selecttype}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="field-block">
                  <label className="label-name">
                    Payment Type<span className="required-symbol">*</span>
                  </label>
                  Cash
                </div>
              )}

              <div className="field-block">
                <label className="label-name">
                  Basic Salary
                </label>
                <input
                  type="text"
                  name="basic_salary"
                  className="input-field"
                  value={salary}
                  readOnly
                />
              </div>

              <div className="field-block">
                <label className="label-name">
                  Date<span className="required-symbol">*</span>
                </label>
                <input
                  type="date"
                  name="finance_date"
                  className="input-field"
                  value={advanceDetails?.finance_date}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  min={today}
                  max={today}
                />
                {errors.finance_date ? (
                  <div className="validation-error-label">
                    {errors.finance_date}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Select File
                  {/* <span className="required-symbol">*</span> */}
                </label>
                <div className="">
                  <div className="flex gap-2 items-center">
                    {fileType !== "image" ? (
                      <FilePresentIcon className="employee-profile-picture" />
                    ) : (
                      <img
                        name="file"
                        className="h-[35px] w-[35px]"
                        src={advanceDetails?.file}
                        alt=""
                      />
                    )}
                    <div className="p-1 bg-[#FFF2EC] rounded flex justify-center items-center gap-1 cursor-pointer">
                      <label for="files" className="label-name cursor-pointer">
                        {advanceDetails?.file === "" ? "Choose" : "Replace"}
                      </label>
                      <input
                        className="w-0 cursor-pointer"
                        id="files"
                        style={{ visibility: "hidden" }}
                        type="file"
                        // accept=".jpeg,.jpg,.png"
                        onChange={(e) => handleProfileImage(e)}
                      />
                      <img src={FileUpload} alt="" />
                    </div>
                  </div>
                  {errors?.file ? (
                    <div className="validation-error-label" key={errors?.file}>
                      {errors?.file}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="field-block">
                <label className="label-name">
                  Advance Remarks
                </label>
                <textarea name="remarks"
                  className="input-field" readOnly
                  >{advanceRemark}</textarea>
              </div>

            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
          <div className="px-5 pb-4">
            <span className="font-karla text-text-dark text-xl">
              Existing Advance Details
            </span>
            <div>
              <table className="log-table mt-[4px] w-full">
                <tr className="bg-[#FFEDC2]">
                  <td className="log-table log-table-data content-label font-extrabold">
                    Employee Code
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                    Amount
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                    Date
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                    Finance Category Name
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                    Payment Type
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                    Paid Amount
                  </td>
                  <td className="log-table log-table-data content-label font-extrabold">
                    PF Employee
                  </td>
                </tr>

                {existingAdvanceDetails?.length === 0 ? (
                  <tr>
                    <td
                      colSpan={6}
                      className="p-4 text-text-light font-karla text-center"
                    >
                      Existing Advance Details For Current Month Not found..!
                    </td>
                  </tr>
                ) : (
                  existingAdvanceDetails?.map((item) => {
                    return (
                      <tr>
                        <td className="log-table log-table-data content-label whitespace-nowrap">
                          {item?.employeeCode}
                        </td>
                        <td className="log-table log-table-data content-label whitespace-nowrap">
                          <span>&#8377;{item?.amount}</span>
                        </td>
                        <td className="log-table log-table-data content-label whitespace-nowrap">
                          <span>{item?.finance_date}</span>
                        </td>
                        <td className="log-table log-table-data content-label">
                          {item?.finance_category_name}
                        </td>
                        <td className="log-table log-table-data content-label">
                          {item?.payment_type}
                        </td>
                        <td className="log-table log-table-data content-label">
                          {item?.paid_amount}
                        </td>
                        <td className="log-table log-table-data content-label">
                          {item?.pf_complaint === 1 ? "Yes" : "No"}
                        </td>
                      </tr>
                    );
                  })
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateAdvance;
