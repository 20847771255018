import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "./Pagination";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.employeeCode}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.EmployeeName}
                      </TableCell>
                    }

                    {/* {
                      <TableCell className="table-body">
                        {row.branchName}
                      </TableCell>
                    } */}
                    {/* {
                      <TableCell className="table-body">
                        {row.department_name}
                      </TableCell>
                    } */}
                    {
                      <TableCell className="table-body">
                        {row.designation_name}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row.clock_in_time}
                      </TableCell>
                    }

                        {
                      <TableCell className="table-body">
                        {row.break_duration}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row.clock_out_time}
                      </TableCell>
                    }

                    {/* {
                      <TableCell className="table-body">
                        <table className=" w-[100%] border border-[#d9d9d9] border-collapse ">
                          <tr>
                            <th className={"p-1"}>Time</th>
                            <th className={"p-1"}>Ip</th>
                          </tr>
                          {JSON.parse(row.logs).map((logs) => {
                            return (
                              <tr>
                                <td className={"p-1"}>{logs?.time}</td>
                                <td className={"p-1"}>{logs?.ip}</td>
                              </tr>
                            );
                          })}
                        </table>
                      </TableCell>
                    } */}
                    
                    {/* {JSON.parse(row.logs).map((logs) => {
                        return <TableCell>{logs?.time}</TableCell>;
                      })} */}
                    {/* {
                        <MoreOption
                          icon={icon}
                          row={row}
                          showDetails={showDetails}
                          multiple={multiple}
                          deleteRow={deleteRow}
                          editPage={editPage}
                          activeEdit={activeEdit}
                          activeDelete={activeDelete}
                        />
                      } */}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
