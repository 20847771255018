import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";
import Validation from "../../../Validation/Validation";
import { GET_API, POST_API } from "../../../Services/api";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import Spinner from "../../Spinner";
// import LoanPrintVoucher from "./LoanPrintVoucher";
import "alertifyjs/build/css/alertify.css";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileUpload from "../../../Assets/Icons/FileUpload.svg";

function UpdateLoan() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const [loanDetails, setLoanDetails] = useState({
    employee_id: [],
    type: "loan",
    selecttype: "cash",
    finance_category_id: "",
    amount: "",
    duration: "",
    finance_date: dayjs(new Date()).format("YYYY-MM-DD"),
    approved_by: "",
    file: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  // const [isPrintVoucher, setIsPrintVoucher] = useState(false);
  // const [printVoucherData, setPrintVoucherData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsLoan, setSelectedOptionsLoan] = useState([]);
  const [isErrors, setIsErrors] = useState({ employee_id: "" });
  const [existingLoanDetails, setExistingLoanDetails] = useState([]);
  const [fileType, setFileType] = useState("");
  const [voiceDet, setVoiceDet] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [actionTypeId, setActionTypeId] = useState(null);
  const [actionCount, setActionCount] = useState(null);
  const [empId, setEmpId] = useState("");

  const [salary, setSalary] = useState("");
  const [loanDedAmt, setLoanDedAmt] = useState("");
  const [loanRemark, setLoanRemark] = useState("");

  const [loanList, setLoanList] = useState([]);

  useEffect(() => {
    getLoanList();
    getEmployeeList();
  }, []);

  const getLoanList = async () => {
    setLoading1(true);
    try {
      const loanList = await GET_API("/financeCategoriesDropdownLoan");
      if (loanList.status === 200) {
        const loan = loanList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        loan.unshift({ value: "", label: "Select..." });
        setLoading1(false);
      }
    } catch (error) {
      alertify.error("Failed to get Loan list...");
      setLoading1(false);
    }
  };

  const getEmployeeList = async () => {
    setLoading2(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const res = employeeList?.data?.data.map((emp, i) => {
          return {
            label: `${emp?.first_name} ${
              emp?.fathers_name ? emp?.fathers_name : ""
            } (${emp?.emp_code})`,
            value: emp?.id,
            salary: emp?.basic_salary,
            loan_deduction_amount: emp?.loan_deduction_amount,
          };
        });
        const approval = employeeList?.data?.data.map((emp, i) => {
          return {
            label: `${emp?.first_name} ${
              emp?.fathers_name ? emp?.fathers_name : ""
            } (${emp?.emp_code})`,
            value: emp?.id,
            salary: emp?.basic_salary,
            loan_deduction_amount: emp?.loan_deduction_amount,
          };
        });
        approval.unshift({ value: "", label: "Select..." });
        setEmployeeList(res);
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
      alertify?.error("Failed to get Employee List...");
    }
  };


  const handleSelectChange = (e, name) => {
    setLoanDetails(() => ({ ...loanDetails, [name]: e?.value }));
  };

  const buttonContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const buttonStyle = {
    marginRight: '10px', // Adds space between the buttons
  };

  const lastButtonStyle = {
    marginRight: '0', // Removes margin from the last button
  };

  // Inline styles
  const modalStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure it's on top of other content
  };

  const buttonDivStyles = {
    paddingTop: '20px',
  }

  const modalContentStyles = {
    backgroundColor: 'white',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  };

  const buttonStyles = {
    margin: '15px',
    padding: '8px 16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007bff',
    color: 'white',
    fontSize: '16px',
  };

  const buttonHoverStyles = {
    backgroundColor: '#0056b3',
  };

  // const printVoucher = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  // useEffect(() => {
  //   if (isPrintVoucher) {
  //     printVoucher();
  //     navigate("/expense/loan");
  //   }
  // }, [isPrintVoucher, printVoucher]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (loanDetails.employee_id.length === 0) {
      newErrors.employee_id = "Employee Name is required";
      valid = false;
      setButtonDisabled(false);
    }
    setIsErrors(newErrors);
    return valid;
  };

  const updateLoan = async (e) => {
    e.preventDefault();
    const isValid = Validation.validateFinanceLoan(loanDetails);
    if (isValid && isValid.error) {
      setButtonDisabled(false);
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      return;
    } else {
      seterrors({});
    }
    if (validateForm()) {
      const userId = localStorage.getItem("user_id");
      POST_API("/addFinanceDetails", {
        ...loanDetails,
        created_by: userId,
        finance_category_id: loanDetails?.finance_category_id,
        approved_by: loanDetails?.approved_by,
        selecttype: loanDetails?.selecttype,
        // employee_id: loanDetails?.employee_id?.value,
      })
        .then(async function (data) {
          setButtonDisabled(false);
          alertify.success(data?.data?.message);
          navigate("/expense/loan");
          // setPrintVoucherData(data?.data?.data);
          // if (selectedOptions?.length <= 1) {
          //   setTimeout(() => {
          //     printVoucher();
          //     setIsPrintVoucher(true);
          //   }, 500);
          // } else {
          //   navigate("/expense/loan");
          // }
        })

        .catch((error) => {
          setButtonDisabled(false);
          alertify.error(error?.response?.data?.message);
        });
    }
  };

const checkExisting = async (id) => {
    setLoading1(true);
    try {
      const existingLoanList = await GET_API("/checkLoanSplitUp/" + id);

      if (existingLoanList?.status === 200) {
        setExistingLoanDetails(existingLoanList?.data?.data);
      } else {
        alertify.error("Failed To get Loan split up Details");
      }
      setLoading1(false);

    } catch (e) {

      setLoading1(false);
      alertify.error("Failed To get Loan split up Details");

    }
};

  const handleEmployeeName = async (e) => {
    setSelectedOptions(e);
    const id = [e?.value];
    const salary = [e?.salary];
    const loanDedAmt = [e?.loan_deduction_amount];
    setLoanDetails(() => ({ ...loanDetails, employee_id: id }));
    getEmployeeLoanList(id);
    setEmpId(id);
    setSalary(salary);
    setLoanDedAmt(loanDedAmt)
  };


  const getEmployeeLoanList = async (id) => {
    setLoading1(true);
    try {
      const empLoanList = await GET_API("/getEmployeeLoanList/" + id);
      if (empLoanList?.status === 200) {
        setExistingLoanDetails(empLoanList?.data?.data);
      } else {
        alertify.error("Failed To get Loan split up Details");
      }
      setLoading1(false);
    } catch (error) {
      alertify.error("Failed to get Loan list...");
      setLoading1(false);
    }
  };


  const skipToNextMonth = (type, fDPId, count) => {
    
    if(type === "next" && count >= 1) {
      alertify.error("Already this loan has been skipped one time.");
      return false;
    }

    if(type === "carry" && count >= 1) {
      alertify.error("Already this loan has been carry forward one time.");
      return false;
    }

    setActionType(type);
    setActionTypeId(fDPId); 
    setShowModal(true);  

  };


  const handleConfirm = async () => {
    setShowModal(false); // Close modal

    try {
      if (actionType === 'next') {
        
        const response = await POST_API("/loanSkipToNextMonth", {
          finance_detail_payment_id: actionTypeId,
          employee_id: empId[0]
        });

        if (response?.status === 200) {
          console.log('Successfully skipped to next month');
          alertify.success("Loan successfully skipped to next month");
          getEmployeeLoanList(empId);
        } else {
          console.error('Failed to skip to next month');
          alertify.error("Failed to skip to next month");
        }
      } else if (actionType === 'carry') {
        
        const response = await POST_API("/loanCarryForward", {
          finance_detail_payment_id: actionTypeId,
          employee_id: empId[0]
        });

        if (response?.status === 200) {
          console.log('Successfully carry forward.');
          alertify.success("Loan successfully carry forward.");
          getEmployeeLoanList(empId);
        } else {
          console.error('Failed to carry forward loan!');
          alertify.error("Failed to carry forward loan!");
        }
      }
    } catch (error) {
      console.error('Error during API call', error);
    }
  };

  // Function to handle cancellation (Close modal)
  const handleCancel = () => {
    setShowModal(false); // Close modal
    console.log('Action canceled');
  };


  const handleLoan = async (e) => {
    const id = [e?.value];
    checkExisting(id);
  };


  const today = new Date().toISOString().split('T')[0];

  const resetForm = () => {
    // setdesignationName("");
    // setdepartmentName("");
    // setbranchName("");
    setLoanDetails({
      type: "loan",
      finance_category_id: "",
      approved_by: "",
      selecttype: "",
      amount: "",
      duration: "",
      employee_id: [],
    });
    setSelectedOptions([]);
    setSelectedOptionsLoan([]);
  };

  if (loading1 || loading2) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/loan" className="in-active">
              Loan List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Update Loan</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Update Loan</div>

          {/***************Form************* **/}
          {/* {isPrintVoucher && (
            <LoanPrintVoucher
              ref={componentRef}
              printVoucherData={printVoucherData}
            />
          )} */}

          <form onSubmit={(e) => updateLoan(e)}>

            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  value={selectedOptions ? selectedOptions : ""}
                  onChange={(e) => handleEmployeeName(e)}
                  options={employeeList}
                  name="employee_id"
                />
                {isErrors["employee_id"] ? (
                  <div className="text-[#FF5C42] text-sm font-medium font-karla">
                    {isErrors["employee_id"]}
                  </div>
                ) : null}
              </div>


              {/* <div className="field-block">
                <label className="label-name">Loan</label>
                <Select
                  options={loanList}
                  value={selectedOptionsLoan ? selectedOptionsLoan : ""}
                  onChange={(e) => handleLoan(e)}
                  name="selecttype"
                  className=""
                  isSearchable
                />
                {errors.selecttype ? (
                  <div className="validation-error-label">
                    {errors.selecttype}
                  </div>
                ) : null}
              </div> */}
              

            </div>

            <div className="px-5 pb-4">
              <span className="font-karla text-text-dark text-xl">
                Upcoming Loan Lists
              </span>
              <div>
                <table className="log-table mt-[4px] w-full">
                  <tr className="bg-[#FFEDC2]">
                    
                    <td className="log-table log-table-data content-label font-extrabold">
                      Finance Date
                    </td>
                    <td className="log-table log-table-data content-label font-extrabold">
                      Amount
                    </td>
                    <td className="log-table log-table-data content-label font-extrabold">
                      Status
                    </td>
                    <td className="log-table log-table-data content-label font-extrabold">
                      Action
                    </td>
                  </tr>

                  {existingLoanDetails?.length === 0 ? (
                    <tr>
                      <td
                        colSpan={6}
                        className="p-4 text-text-light font-karla text-center"
                      >
                        Existing Loan Details For Current Month Not found..!
                      </td>
                    </tr>
                  ) : (
                    existingLoanDetails?.map((item) => {
                      return (
                        <tr>
                          
                          <td className="log-table log-table-data content-label whitespace-nowrap">
                            {item?.actual_due_date}
                          </td>
                          <td className="log-table log-table-data content-label whitespace-nowrap font-black">
                            <span>&#8377;{item?.amount}</span>
                          </td>
                          <td
                            className="log-table log-table-data content-label"
                            style={{
                              color: item?.status === 1 ? 'green' : 'orange',
                              backgroundColor: item?.status === 1 ? 'lightgreen' : 'lightyellow', textAlign: 'center'
                            }}
                          >
                            {item?.status === 1 ? "Paid" : "Yet to pay"}
                          </td>
                          <td className="log-table log-table-data content-label" style={{
                              color: item?.approval_status === 1 ? '' : 'red',
                              backgroundColor: item?.approval_status === 1 ? '' : '', textAlign: 'center'
                            }}>
                          {
                                item?.status === 1 
                                  ? "" 
                                  : item?.approval_status === 0 
                                    ? "Approval Pending" 
                                    : (
                                        <div style={buttonContainerStyle}>
                                          <button 
                                            type="button" 
                                            className="submit-button" 
                                            style={buttonStyle} 
                                            onClick={() => skipToNextMonth('next', item?.id, item?.skip_to_next_count)}
                                          >
                                            <span className="submit-button-label">Skip to Next Month</span>
                                          </button>
                                          <button 
                                            type="button" 
                                            className="submit-button" 
                                            style={{ ...buttonStyle, ...lastButtonStyle }} 
                                            onClick={() => skipToNextMonth('carry', item?.id, item?.carry_count)}
                                          >
                                            <span className="submit-button-label">Carry Forward</span>
                                          </button>
                                        </div>
                                      )
                              }

                          </td>
                        </tr>
                      );
                    })
                  )}
                </table>
              </div>
            </div>
            
          </form>

          {showModal && (
            <div style={modalStyles}>
            <div style={modalContentStyles}>
              <p>Are you sure you want to skip / carry forward loan?</p>
              <div style={buttonDivStyles}>
                <button
                  onClick={handleConfirm}
                  style={{ ...buttonStyles, ...buttonHoverStyles }}
                >
                  Yes
                </button>
                <button
                  onClick={handleCancel}
                  style={{ ...buttonStyles, ...buttonHoverStyles }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
          )}

        </div>
      </div>
    </div>
  );
}

export default UpdateLoan;
