import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";

export default function StickyHeadTable({
  list,
  showDetails,
  totalAmount,
  columns,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                const father_name = row?.fathers_name ? row?.fathers_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                     style={ { background: row.print_status === 0 ? "#ffffff" : "#ecc583", } }
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell
                        className="table-body"
                        onClick={() => showDetails(row)}
                      >
                        <span
                          className="text-sky-600 border-b-[1px] border-b-[#0284C7] cursor-pointer"
                          style={{ color: "#0284C7" }}
                        >
                          {row.EmployeeCode}
                        </span>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name + " " + father_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.payment_type}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body loan-advance-expence-table">
                        {row.amount}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body loan-advance-expence-table">
                        {row.paid_amount}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body loan-advance-expence-table">
                        {row.amount - row.paid_amount}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.voucher_status === 1 ? (
                          <span style={{ color: "#3EAB82" }}>Paid</span>
                        ) : (
                          <span style={{ color: "#ed452a" }}>Unpaid</span>
                        )}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.status === 0 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Approved
                          </span>
                        ) : row?.status === 3 ? (
                          <span
                            className="bg-[#EEC9C9] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Pending
                          </span>
                        ) : row?.status === 2 ? (
                          <span
                            className="bg-[#ed452a] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Rejected
                          </span>
                        ) : null}
                      </TableCell>
                    }
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="grid grid-cols-6 p-2 bg-white-color">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div className="text-text-dark font-karla text-end">Total Amount</div>
        <div className="text-center text-text-dark font-karla">
          {totalAmount}
        </div>
      </div>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
