import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "./Pagination";

export default function DataTable({
  list,
  setSelectedRows,
  setPageNo,
  setPageSize,
  lastPage,
  currentPage,
  pageSize,
}) {
  const columns = [
    { field: "first_name", headerName: "Employee Name", minWidth: 180 },
    { field: "employee_code", headerName: "Employee Code", minWidth: 150 },
    { field: "amount", headerName: "Amount", minWidth: 150 },
    { field: "type", headerName: "Type", minWidth: 150 },
    { field: "from_month", headerName: "From Month", minWidth: 150 },
    { field: "to_month", headerName: "To Month", minWidth: 150 },
    { field: "created_at", headerName: "Created Date", minWidth: 130 },
  ];

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };
  const heightStyle =
    list.length === 0 ? { height: 320, width: "100%" } : { width: "100%" };
  return (
    <div style={heightStyle}>
      <DataGrid
        rows={list}
        columns={columns}
        checkboxSelection
        sx={{
          ".MuiDataGrid-footerContainer": {
            display: "none",
          },
        }}
        onRowSelectionModelChange={handleSelectionModelChange}
      />
      <div className="bg-[#fff]">
        <Pagination
          setPageNo={setPageNo}
          setPageSize={setPageSize}
          lastPage={lastPage}
          currentPage={currentPage}
          pageSize={pageSize}
          shape="rounded"
        />
      </div>
    </div>
  );
}
