import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import alertify from "alertifyjs";
import Select from "react-select";
import "alertifyjs/build/css/alertify.css";
import Validation from "../../Validation/Validation";
import { GET_API, POST_API } from "../../Services/api";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Spinner from "../Spinner";

function CreateFine() {
  const navigate = useNavigate();
  useEffect(() => {
    getEmployeeList();
    getBonusList();
  }, []);

  const [fineDetails, setfineDetails] = useState({
    employee_id: "",
    fine_type_id: "",
    is_manual: 1,
    remarks: "",
    fine_amount: "",
  });
  const [errors, seterrors] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [bonusList, setBonusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const today = new Date().toISOString().split('T')[0];

  const getBonusList = async () => {
    try {
      setLoading2(true);
      const designationList = await GET_API("/financeCategoriesDropdownFine");
      if (designationList.status === 200) {
        const bonus = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        bonus.unshift({ value: "", label: "Select..." });
        setBonusList(bonus);
        setLoading2(false);
        return;
      }
    } catch (error) {
      setLoading2(false);
      alertify.error(error?.response?.message);
    }
  };

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.data?.status === 200) {
        const empList = employeeList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alertify?.error("Failed to Get Employee List...");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setfineDetails(() => ({ ...fineDetails, [name]: value }));
  };
  const handleSelectChange = (e, name) => {
    setfineDetails(() => ({ ...fineDetails, [name]: e?.value }));
  };
  const createFine = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const isValid = Validation.validateFine(fineDetails);
    if (isValid && isValid.error) {
      seterrors({
        [isValid?.error?.details[0].path[0]]: isValid.error.details[0].message,
      });
      setButtonDisabled(false);
      return;
    } else {
      seterrors({});
    }
    try {
      const addFine = await POST_API("/employeeFineAdd", fineDetails);
      if (addFine.status === 200) {
        setButtonDisabled(false);
        alertify.success(addFine?.data?.message);
        navigate("/fineList");
      }
    } catch (error) {
      setButtonDisabled(false);
      alertify.error(error?.response?.data?.message);
    }
  };
  const resetForm = () => {
    setfineDetails({
      employee_id: "",
      is_manual: 1,
      fine_type_id: "",
      remarks: "",
      fine_amount: "",
    });
  };
  if (loading || loading2) {
    return <Spinner />;
  }
  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/fineList" className="in-active">
              Employee Fine List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Employee Fine</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Employee Fine</div>

          {/***************Form************* **/}

          <form onSubmit={(e) => createFine(e)}>
            <div className="form">
              <div className="field-block">
                <label className="label-name">
                  Employee Name
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={employeeList}
                  value={employeeList?.find(
                    (option) => option?.value === fineDetails.employee_id
                  )}
                  onChange={(e) => handleSelectChange(e, "employee_id")}
                  name="employee_id"
                  className=""
                  isSearchable
                />
                {errors.employee_id ? (
                  <div className="validation-error-label">
                    {errors.employee_id}
                  </div>
                ) : null}
              </div>

              <div className="field-block">
                <label className="label-name">
                  Fine Type
                  <span className="required-symbol">*</span>
                </label>
                <Select
                  options={bonusList}
                  value={bonusList?.find(
                    (option) => option?.value === fineDetails.fine_type_id
                  )}
                  onChange={(e) => handleSelectChange(e, "fine_type_id")}
                  name="fine_type_id"
                  className=""
                  isSearchable
                />
                {errors.fine_type_id ? (
                  <div className="validation-error-label">
                    {errors.fine_type_id}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Fine Amount
                  <span className="required-symbol">*</span>
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="fine_amount"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={fineDetails?.fine_amount}
                  placeholder="Fine Amount"
                />
                {errors.fine_amount ? (
                  <div className="validation-error-label">
                    {errors.fine_amount}
                  </div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Date
                  <span className="required-symbol">*</span>
                </label>
                <input
                  type="date"
                  name="date"
                  className="input-field"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  min={today}
                />
                {errors.date ? (
                  <div className="validation-error-label">{errors.date}</div>
                ) : null}
              </div>
              <div className="field-block">
                <label className="label-name">
                  Remarks
                  {/* <span className="required-symbol">*</span> */}
                </label>
                <textarea
                  className="input-field"
                  type="text"
                  name="remarks"
                  autoComplete="off"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Remarks"
                  rows={5}
                  value={fineDetails?.remarks}
                />
                {errors.remarks ? (
                  <div className="validation-error-label">{errors.remarks}</div>
                ) : null}
              </div>
            </div>

            <div className="submit-reset-button">
              <button
                type="button"
                className="reset-button"
                onClick={resetForm}
              >
                <span className="reset-button-label">Reset</span>
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className="submit-button"
              >
                <span className="submit-button-label">Add</span>
                <img src={ColorAdd} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateFine;
