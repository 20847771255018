import React, { useState, useEffect, useContext } from "react";
import alertify from "alertifyjs";
import FinanceApproveTable from "../../DataTable/FinanceApproveTable";
import Search from "../../../Assets/Icons/Search.svg";
import Spinner from "../../Spinner";
import { GET_API, POST_API } from "../../../Services/api";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../../PrivilegeContext";
import Filter from "../../../Assets/Icons/Filter.svg";
import Select from "react-select";

function FinanceApproval() {
  const [loading, setLoading] = useState(false);
  const [financeList, setFinanceList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [hragentList, sethragentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [filter, setFilter] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");

  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isShiftLoading, setIsShiftLoading] = useState(false);
  const [isHrAgentLoading, setIsHrAgentLoading] = useState(false);

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    paid_status: "",
    employee_id: "",
    shift: "",
    agent: "",
    payment_type: "",
    type: "",
    from_date: "",
    to_date: "",
  });

  useEffect(() => {
    getFinanceApproveList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  useEffect(() => {
    // getAccess();
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmployeeNameList();
    getShiftList();
    getHRAgentList();
  }, []);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "approve_finance") {
        setAddAccess(item?.add);
      }
      return "";
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const headCells = [
    { field: "EmployeeCode", headerName: "Employee Code", minWidth: 150 },
    { field: "employee_name", headerName: "Employee Name", minWidth: 200 },
    { field: "department_name", headerName: "Department", minWidth: 150 },
    { field: "remarks", headerName: "Remark", minWidth: 320 },
    { field: "payment_type", headerName: "Payment Type", minWidth: 150 },
    { field: "type", headerName: "Type", minWidth: 150 },
    { field: "amount", headerName: "Amount", minWidth: 150 },
  ];


  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const paymentType = [
    { value: "", label: "Please Select" },
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
  ];

  const financeType = [
    { label: "Please Select", value: "" },
    { label: "loan", value: "Loan" },
    { label: "advance", value: "Advance" },
  ];
  
  const getBranchList = async () => {
    try {
      setIsBranchLoading(true);
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setBranchList([]);
      setIsBranchLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setEmployeetype([]);
      setIsEmpTypeLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setDepartment([]);
      setIsDepartmentLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setDesignation([]);
      setIsDesignationLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getEmployeeNameList = async () => {
    setIsEmpLoading(true);
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      alertify.error("Failed to get Employee List");
    }
  };

  const getShiftList = async () => {
    setIsShiftLoading(true);
    try {
      const shiftList = await GET_API("/shiftDropdown");
      if (shiftList.status === 200) {
        const floor = shiftList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setShiftList(floor);
        setIsShiftLoading(false);
        return;
      }
    } catch (error) {
      alertify.error("Failed to Get Shift List");
      setIsShiftLoading(false);
    }
  };

  const getHRAgentList = async () => {
    setIsHrAgentLoading(true);
    try {
      const HRAgent = await GET_API("/agentDropdown/security_agent");
      if (HRAgent?.data?.status === 200) {
        const agent = HRAgent?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select..." });
        sethragentList(agent);
        setIsHrAgentLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get HR Agent List...");
      setIsHrAgentLoading(false);
    }
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    };

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getFinanceApproveList(1, pageSize);
  };

  const getFinanceApproveList = async (page, size, filters, search) => {
    setLoading(true);
    try {
      const financeList = await GET_API(
        `/getFinanceDetailsApproval?page=${page}&size=${size}${filters}&search=${search}`
      );
      if (financeList?.status === 200) {
        if (
          financeList?.data?.data?.current_page >
          financeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setFinanceList(financeList?.data?.data?.data);
        setLastPage(financeList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading ||
    isShiftLoading ||
    isHrAgentLoading
  ) {
    return <Spinner />;
  }

  const handleApprove = async (type) => {
    const data = {
      selectedRows,
      appType: type,
    };
    if (selectedRows.length <= 0) {
      alertify
        .alert("Please select Row to Approve/Reject")
        .setHeader("<b> Alert </b> ");
      return;
    }

    try {
      const approveFinance = await POST_API(
        "/updateFinanceDetailsApprovalstatus",
        data
      );
      if (approveFinance?.status === 200) {
        alertify.success(`Finance ${data?.appType}d successfully...!`);
        getFinanceApproveList(1, 10);
        return;
      }
    } catch (error) {
      alertify.error(`Failed to ${data?.appType}d Finance`);
    }
  };

  return (
    <div className=" bg-[#F7F6F4] grow w-[calc(100%-80px-20%)]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Finance Logs</h4>
          <div className="search-block">
    <div className="search">
      <input
        type="text"
        className="search-input-box"
        placeholder="Search..."
        autoComplete="off"
        style={{ outline: "none", border: "none" }}
        onChange={(e) => setSearchKey(e?.target?.value)}
        value={searchKey}
      />
      <img
        src={Search}
        className="cursor-pointer"
        onClick={() => {
          getFinanceApproveList(
            currentPage,
            pageSize,
            filterParams,
            searchKey
          );
        }}
        alt=""
      />
    </div>
    <div className="flex gap-3">
      <div
        className="filter-button"
        onClick={() => setFilter((prev) => !prev)}
      >
        <span className="filter-btn-label">Filter</span>
        <img src={Filter} alt="" />
      </div>
      <div
            className="add-new-button-block"
            onClick={() => {
              handleApprove("reject");
            }}
          >
            <button
              to=""
              className="add-new-button"
              // disabled={selectedRows?.length >= 1 ? false : true}
            >
              <span>Reject</span>
            </button>
          </div>
          <div
            className="add-new-button-block"
            onClick={() => {
              handleApprove("approve");
            }}
          >
            <button
              to=""
              className="add-new-button"
              // disabled={selectedRows?.length >= 1 ? false : true}
            >
              <span>Approve</span>
            </button>
          </div>

    </div>
  </div>
  {filter && (
    <form>
      <div className="form ">
        <div className="field-block dropdown-select-to-first">
          <label className="label-name">Branch Name</label>
          <Select
            options={branchList}
            value={branchList?.find(
              (option) => option?.value === filterList.branch_id
            )}
            onChange={(e) => handleFilter(e, "branch_id", "select")}
            name="branch_id"
            className=""
            isSearchable
          />
        </div>
        <div className="field-block dropdown-select-to-first">
          <label className="label-name">Employee Name</label>
          <Select
            options={employeeName}
            value={employeeName?.find(
              (option) => option?.value === filterList.employee_id
            )}
            onChange={(e) => handleFilter(e, "employee_id", "select")}
            name="employee_id"
            className=""
            isSearchable
          />
        </div>
        <div className="field-block dropdown-select-to-first">
          <label className="label-name">Employee Type</label>
          <Select
            options={employeetype}
            value={employeetype?.find(
              (option) => option?.value === filterList.employee_type_id
            )}
            onChange={(e) =>
              handleFilter(e, "employee_type_id", "select")
            }
            name="employee_type_id"
            className=""
            isSearchable
          />
        </div>
        <div className="field-block dropdown-select-to-second">
          <label className="label-name">Gender</label>
          <Select
            options={genderList}
            value={genderList?.find(
              (option) => option?.value === filterList.gender
            )}
            onChange={(e) => handleFilter(e, "gender", "select")}
            name="gender"
            className=""
            isSearchable
          />
        </div>
        <div className="field-block dropdown-select-to-second">
          <label className="label-name">Department</label>
          <Select
            options={department}
            value={department?.find(
              (option) => option?.value === filterList.department_id
            )}
            onChange={(e) => handleFilter(e, "department_id", "select")}
            name="department_id"
            className=""
            isSearchable
          />
        </div>
        <div className="field-block dropdown-select-to-second">
          <label className="label-name">Designation</label>
          <Select
            options={designation}
            value={designation?.find(
              (option) => option?.value === filterList.designation_id
            )}
            onChange={(e) =>
              handleFilter(e, "designation_id", "select")
            }
            name="designation_id"
            className=""
            isSearchable
          />
        </div>
        
        <div className="field-block dropdown-select-to-fourth">
          <label className="label-name">Payment Type</label>
          <Select
            options={paymentType}
            value={paymentType?.find(
              (option) => option?.value === filterList?.payment_type
            )}
            onChange={(e) => handleFilter(e, "payment_type", "select")}
            name="payment_type"
            className=""
            isSearchable
          />
        </div>

        <div className="field-block dropdown-select-to-fourth">
          <label className="label-name">Finance Type</label>
          <Select
            options={financeType}
            value={financeType?.find(
              (option) => option?.value === filterList?.type
            )}
            onChange={(e) => handleFilter(e, "type", "select")}
            name="type"
            className=""
            isSearchable
          />
        </div>

        <div className="field-block">
          <label className="label-name">From & To Date</label>
          <div className="flex gap-1 justify-center items-center">
            <input
              className="input-field "
              type="date"
              autoComplete="off"
              name="from_date"
              value={filterList?.from_date}
              onChange={(e) => handleFilter(e, "from_date", "date")}
            />
            <label className="label-name">to</label>
            <input
              className="input-field "
              type="date"
              autoComplete="off"
              name="to_date"
              value={filterList?.to_date}
              onChange={(e) => handleFilter(e, "to_date", "date")}
            />
          </div>
        </div>
        <div className="field-block flex justify-center">
          <div className="add-new-button-block w-[40%] h-max">
            <button
              className="add-new-button"
              onClick={(e) => {
                showFilterResult(e);
              }}
            >
              <span style={{ cursor: "pointer" }}>Show Result</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  )}
          {/**************** * Table ********************/}
          <div className="p-5 min-h-[500px]">
            <FinanceApproveTable
              columns={headCells}
              list={financeList}
              setSelectedRows={setSelectedRows}
              setPageNo={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinanceApproval;
