import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import alertify from "alertifyjs";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import LeaveReportTable from "../../DataTable/LeaveReportTable";
import Spinner from "../../Spinner";
import Filter from "../../../Assets/Icons/Filter.svg";
import More from "../../../Assets/Icons/More.svg";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";
import Search from "../../../Assets/Icons/Search.svg";
import ButtonLoader from "../../ButtonLoader";

function LeaveReportList() {
  const navigate = useNavigate();
  const [filteredList, setfilteredList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaveType, setLeaveType] = useState([]);
  const [filter, setFilter] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [isFloorLoading, setIsFloorLoading] = useState(false);
  const [selectedFloor, setSelectedFloor] = useState([]);

  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [isEmpLoading, setIsEmpLoading] = useState(false);
  const [isEmpTypeLoading, setIsEmpTypeLoading] = useState(false);
  const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);
  const [isDesignationLoading, setIsDesignationLoading] = useState(false);
  const [isLeaveTypeLoading, setIsLeaveTypeLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [filterParams, setFilterParams] = useState("");
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);

  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_type_id: "",
    employee_id: "",
    leave_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    from_date: "",
    to_date: "",
    shift: "",
    agent: "",
    religion: "",
    caste: "",
  });

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "emp_code", label: "Employee Code" },
    { id: "name", label: "Employee Name" },
    { id: "DepartmentName", label: "Department Name" },
    { id: "from_date", label: "From Date" },
    { id: "to_date", label: "To Date" },
    { id: "no_of_days", label: "No Of Days" },
    { id: "leave_type_name", label: "Leave Type" },
    { id: "more_icon", label: "" },
  ];

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmployeeList();
    getLeaveList();
    getFloorList();
  }, []);

  useEffect(() => {
    getLeaveReportList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const getBranchList = async () => {
    setIsBranchLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setIsBranchLoading(false);
      }
    } catch (e) {
      setIsBranchLoading(false);
      setBranchList([]);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setIsEmpTypeLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setIsEmpTypeLoading(false);
      }
    } catch (e) {
      setIsEmpTypeLoading(false);
      setEmployeetype([]);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getFloorList = async () => {
    setIsFloorLoading(true);
    try {
      const getfloorList = await GET_API("/floorDropdown");
      if (getfloorList?.data?.status === 200) {
        const floorList = getfloorList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floorList.unshift({ value: "", label: "Select..." });
        setFloorList(floorList);
        setIsFloorLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Floor List");
      setIsFloorLoading(false);
    }
  };
  
  const getDepartmentList = async () => {
    setIsDepartmentLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setIsDepartmentLoading(false);
      }
    } catch (e) {
      setIsDepartmentLoading(false);
      setDepartment([]);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setIsDesignationLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setIsDesignationLoading(false);
      }
    } catch (e) {
      setIsDesignationLoading(false);
      setDesignation([]);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getexportData = (data) => {
    const newarr = [];
    const updatedList = data.map((obj) => {
      if (obj?.LeaveId === null) {
        const updatedObj = { ...obj };
        updatedObj.LeaveId = "Unplanned Leave";
        newarr.push(updatedObj);
      } else {
        const updatedObj = { ...obj };
        updatedObj.LeaveId = "Planned Leave";
        newarr.push(updatedObj);
      }
    });

    setExportList(newarr);
  };

  const isPageNotExit = () => {
    setCurrentPage(1);
    getLeaveReportList(1, pageSize, filterParams, searchKey);
  };

  const getLeaveReportList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const list = await GET_API(
        `/employeeLeaveReport?page=${page}&size=${size}${filters}&search=${search}`
      );

      if (list.status === 200) {
        if (list?.data?.data?.current_page > list?.data?.data?.last_page) {
          isPageNotExit();
          return;
        }

        setfilteredList(list?.data?.data?.data);
        setLeaveList(list?.data?.data?.data);
        setLoading(false);
        setLastPage(list?.data?.data?.last_page);
        getexportData(list?.data?.data?.data);
      }
    } catch (error) {
      alertify.error("Failed to get Employee Report List");
      setLoading(false);
    }
  };

  const getLeaveList = async () => {
    setIsLeaveTypeLoading(true);
    GET_API("/DropdownleaveTypeList")
      .then(async function (data) {
        const leaveList = data?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        leaveList.unshift({ value: "", label: "Select..." });
        setLeaveType(leaveList);
        setIsLeaveTypeLoading(false);
      })
      .catch((error) => {
        setIsLeaveTypeLoading(false);
        setLeaveType([]);
      });
  };

  const getEmployeeList = async () => {
    setIsEmpLoading(true);
    try {
      const employeeList = await GET_API("/employeeDropdown");
      if (employeeList?.status === 200) {
        const empList = employeeList?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeList(empList);
        setIsEmpLoading(false);
      }
    } catch (error) {
      setIsEmpLoading(false);
      setEmployeeList([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const exportData = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-Leave?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "TerminationReport.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const handleExportChange = (event) => {
    const value = event.target.value;

    if (value === 'excel') {
      setIsExportLoading(true);
      exportData(); // Call your export function
    } else if (value === 'pdf') {
      setIsExportPdfLoading(true);
      exportAsPDF(); // Call your export function
    }
  };

  const exportAsPDF = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(
        `leaveReportPdf?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "Loan.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };

  const getFloorID = (e) => {
    let floorID = [...e]?.map((emp, i) => {
      return emp.value;
    });
    return floorID;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;

    if (name === "floor") {
      var id = getFloorID(e);
      setSelectedFloor(e);
    }

    // filterList[name] = value;
    filterList[name] = name === "floor" ? id : value;

    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0;
    };

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const showDetails = (listDetail) => {
    const id = listDetail?.emp_code;
    navigate(`/report/leave/details/${id}`, {
      state: {
        list: listDetail,
      },
    });
  };

  if (
    loading ||
    isBranchLoading ||
    isEmpLoading ||
    isEmpTypeLoading ||
    isDepartmentLoading ||
    isDesignationLoading ||
    isLeaveTypeLoading
  ) {
    return <Spinner />;
  }

  return (
    <div className="grow bg-[#F7F6F4]">
      <div className="sm-container">
        <div className="card-wrapper">
          <h4 className="card-header">Leave Report List</h4>
          <form
            onSubmit={() =>
              getLeaveReportList(currentPage, pageSize, filterParams, searchKey)
            }
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="flex gap-3">
                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>
                
                <div className="export-dropdown dropdown-select-to-second">
                    <select onChange={handleExportChange} defaultValue="" style={{ backgroundColor: 'rgb(217 151 25)', padding: '10px', borderRadius: '5px' }}>
                      <option value="" disabled>Select Export Type</option>
                      <option value="excel">
                        {isExportLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as Excel
                            <img src={ExcelExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                      <option value="pdf">
                        {isExportPdfLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as PDF
                            <img src={PDFExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                    </select>
                </div>

              </div>
            </div>
          </form>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeList}
                    value={employeeList?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Leave Type</label>
                  <Select
                    options={leaveType}
                    value={leaveType?.find(
                      (option) => option?.value === filterList.leave_type_id
                    )}
                    onChange={(e) => handleFilter(e, "leave_type_id", "select")}
                    name="leave_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "employee_type_id", "select")
                    }
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "designation_id", "select")
                    }
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-first">
                    <label className="label-name">Floor</label>
                    <Select
                      options={floorList}
                      value={selectedFloor ? selectedFloor : []}
                      onChange={(e) => handleFilter(e, "floor", "select")}
                      name="floor"
                      className=""
                      isSearchable
                      isMulti
                    />
                  </div>

                <div className="field-block">
                  <label className="label-name">Leave Date</label>
                  <div className="">
                    <input
                      className="input-field w-full"
                      type="date"
                      autoComplete="off"
                      name="leave_date"
                      value={filterList?.leave_date}
                      onChange={(e) => handleFilter(e, "leave_date", "date")}
                    />
                  </div>
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <LeaveReportTable
            list={filteredList}
            columns={columns}
            activeEdit={false}
            activeDelete={false}
            multiple={true}
            icon={More}
            showDetails={showDetails}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default LeaveReportList;
