import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import View from "../../Assets/Icons/View.svg";
import { GET_API, POST_API } from "../../Services/api";
import alertify from "alertifyjs";
import Spinner from "../Spinner";
import BulkBonusTable from "../DataTable/BulkBonusTable";
import ColorAdd from "../../Assets/Icons/ColorAdd.svg";
import Select from "react-select";
import Filter from "../../Assets/Icons/Filter.svg";
import Search from "../../Assets/Icons/Search.svg";
import ButtonLoader from "../ButtonLoader";

function AddBulkBonus() {
  const navigate = useNavigate();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [bonusType, setBonusType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [showFilterResultData, setShowFilterResultData] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const [isFloorLoading, setIsFloorLoading] = useState(false);
  const [isBloodGroupLoading, setIsBloodGroupLoading] = useState(false);
  const [isReligionLoading, setIsReligionLoading] = useState(false);
  const [isCasteLoading, setIsCasteLoading] = useState(false);
  const [isAgentLoading, setIsAgentLoading] = useState(false);

  const [employeeList, setEmployeeList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [bloodgroupList, setBloodgroupList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [casteList, setCasteList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [filterList, setfilterList] = useState({
    branch_id: "",
    employee_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    from_date: "",
    district: "",
    town: "",
    pf_employee: "",
    to_date: "",
    approve_status: "",
    joining_from_date: "",
    joining_to_date: "",
  });

  const [filterParams, setFilterParams] = useState("");
  const [bonusTypeValue, setBonusTypeValue] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const handleBonusType = (e) => {
    setBonusTypeValue((prev) => e?.value);
  };
  const [bulkBonus, setbulkBonus] = useState({
    created_by: localStorage?.getItem("user_id"),
    data: [],
  });

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getEmployeeNameList();
    getDesignationList();
    getFloorList();
    getBloodBroupList();
    getReligion();
    getCaste();
    getAgent();
    getBonusType();
    // getDistrictList();
    // getTownList();
  }, []);

  useEffect(() => {
    getBulkBonus(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  const isPageNotExit = () => {
    setCurrentPage(1);
    getBulkBonus(1, pageSize, filterParams, searchKey);
  };

  const getEmployeeList = async (page, size, filters, search) => {
    try {
      setLoading(true);
      const employeeList = await GET_API(
        `/employeesList?page=${page}&size=${size}${filters}&search=${search}`
      );

      if (employeeList.status === 200) {
        if (
          employeeList?.data?.data?.current_page >
          employeeList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }

        const employeeListArr = [];
        employeeList?.data?.data?.data?.map((item) => {
          return employeeListArr.push(item);
        });
        const empList = employeeList?.data?.data?.data.map((emp) => {
          return {
            label: `${emp?.first_name} (${emp?.emp_code})`,
            value: emp?.employee_id,
          };
        });
        empList.unshift({ label: "Select...", value: "" });
        setEmployeeList(employeeListArr);
        setFilteredEmployee(employeeList?.data?.data?.data);
        setLastPage(employeeList?.data?.data?.last_page);
        setLoading(false);
      }
    } catch (e) {
      alertify.error("Failed to get Employee List");
    }
  };

  const getEmployeeNameList = async () => {
    try {
      const empName = await GET_API("/employeeDropdown");
      if (empName?.status === 200) {
        const empList = empName?.data?.data.map((dep, i) => {
          return {
            value: dep?.id,
            label: `${dep?.first_name} (${dep?.emp_code})`,
          };
        });
        empList.unshift({ value: "", label: "Select..." });
        setEmployeeName(empList);
      }
    } catch (error) {
      setEmployeeName([]);
      alertify.error("Failed to get Employee List");
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getBonusType = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/FinanceCategoriesListbonusDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        branch.unshift({ value: "", label: "Select..." });
        setBonusType(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Please Select" });
        setEmployeetype(type);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getFloorList = async () => {
    setIsFloorLoading(true);
    try {
      const floorList = await GET_API("/floorDropdown");
      if (floorList.status === 200) {
        const floor = floorList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        floor.unshift({ value: "", label: "Select..." });
        setFloorList(floor);
        setIsFloorLoading(false);
      }
    } catch (e) {
      setIsFloorLoading(false);
      alertify.error("Failed to Get Floor List");
    }
  };

  const getBloodBroupList = async () => {
    setIsBloodGroupLoading(true);
    try {
      const bloodGroup = await GET_API("/bloodGroupDropdown");
      if (bloodGroup?.data?.status === 200) {
        const blood = bloodGroup?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        blood.unshift({ value: "", label: "Select..." });
        setBloodgroupList(blood);
        setIsBloodGroupLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get Blood group list");
      setIsBloodGroupLoading(false);
    }
  };

  const getReligion = async () => {
    setIsFloorLoading(true);
    try {
      const religionList = await GET_API("/religionDropdown");
      if (religionList.status === 200) {
        const religion = religionList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        religion.unshift({ value: "", label: "Select..." });
        setReligionList(religion);
        setIsReligionLoading(false);
      }
    } catch (e) {
      setIsReligionLoading(false);
      alertify.error("Failed to Get Religions List");
    }
  };

  const getCaste = async () => {
    setIsCasteLoading(true);
    try {
      const casteList = await GET_API("/casteDropdown");
      if (casteList.status === 200) {
        const caste = casteList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        caste.unshift({ value: "", label: "Select..." });
        setCasteList(caste);
        setIsCasteLoading(false);
      }
    } catch (e) {
      setIsCasteLoading(false);
      alertify.error("Failed to Get Caste List");
    }
  };

  const getAgent = async () => {
    setIsAgentLoading(true);
    try {
      const getType = await GET_API("/agentDropdown/consultancy");
      if (getType.status === 200) {
        const agent = getType?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.company_name };
        });
        agent.unshift({ value: "", label: "Select Agent" });
        setAgentList(agent);
        setIsAgentLoading(false);
      }
    } catch (error) {
      setIsAgentLoading(false);
      setAgentList([]);
      alertify.error("Failed to get Agent List...");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getBulkBonus = async (page, size, search) => {
    try {
      setLoading1(true);
      const bulkBonus = await GET_API(
        `/getBulkbonus?page=${page}&size=${size}${filterParams}&search=${search}`
      );
      if (bulkBonus?.data?.status === 200) {
        if (
          bulkBonus?.data?.data?.current_page > bulkBonus?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setLastPage(bulkBonus?.data?.data?.last_page);
        setEmployee(bulkBonus?.data?.data?.data);
        setLoading1(false);
      }
    } catch (error) {
      setLoading1(false);
      setLastPage("");
      setEmployee([]);
      alertify?.error("Failed to get Bulk Bonus List...");
    }
  };

  const columns = [
    { id: "s.no", label: "S.No" },
    { id: "emp_name", label: "Employee Name" },
    { id: "emp_code", label: "Employee Code" },
    { id: "salaryDetails", label: "Basic Salary" },
    { id: "EmployeeJoiningDate", label: "Employee Joining Date" },
    { id: "employmentDuration", label: "Working Days" },
    { id: "holidayschemename", label: "HS" },
    { id: "amount", label: "Amount" },
    { id: "action", label: "Action" },
  ];

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const approveStatusList = [
    { label: "Please Select", value: "" },
    { label: "All", value: "all" },
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
    { label: "Peding", value: "pending" },
    { label: "Terminated", value: "terminated" },
  ];

  const validEmployee = [
    { label: "Please Select", value: "" },
    { label: "Valid", value: "valid" },
    { label: "Invalid", value: "invalid" },
  ];

  const employeePFTypeList = [
    { label: "Please Select", value: "" },
    { label: "PF Employee", value: 1 },
    { label: "Non PF Employee", value: 0 },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const handleBulkBonusCalculation = async () => {
    try {
      if (bonusTypeValue == "") {
        alertify?.error("Please choose Bonus Type and then submit");
        return false;
      }
      const bulkBonusList = await POST_API(
        `/addBulkBonusDetails/${bonusTypeValue}`,
        bulkBonus
      );
      if (bulkBonusList?.data?.status === 200) {
        navigate("/expense/bonus");
        alertify?.success(bulkBonusList?.data?.message);
      }
    } catch (error) {
      alertify?.error("Failed to Add Bonus...");
    }
  };

  if (loading1 || loading2 || loading3 || loading4 || loading5 || loading6)
    return <Spinner />;

  return (
    <div className="bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="bread-crumbs">
          <div>
            <Link to="/expense/bonus" className="in-active">
              Bonus Details List
            </Link>
            <Link>{" > "}</Link>
            <Link className="active">Add Bulk Bonus</Link>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="card-header">Add Bulk Bonus</div>{" "}
          <form
            onSubmit={() =>
              getEmployeeList(currentPage, pageSize, filterParams, searchKey)
            }
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="flex gap-3">
                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>
                <div className="dropdown-select-to-first">
                  <Select
                    options={bonusType}
                    onChange={(e) => handleBonusType(e)}
                    placeholder="Please Select"
                    name="bonus_type_id"
                    className=""
                    isSearchable
                    value={
                      bonusTypeValue
                        ? bonusType.find(
                            (option) => option.value === bonusTypeValue
                          )
                        : ""
                    }
                  />
                </div>
                <div className="add-new-button-block">
                  <button
                    className="add-new-button"
                    type="button"
                    disabled={bulkBonus?.data?.length === 0 ? true : false}
                    onClick={() => handleBulkBonusCalculation()}
                  >
                    <span>Add</span>
                    <img src={ColorAdd} alt="add" />
                  </button>
                </div>
              </div>
            </div>
          </form>
          {filter && (
            <form>
              <div className="form ">
                <div
                  className="field-block dropdown-select-to-first"
                  style={{ zIndex: "1000000" }}
                >
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-first"
                  style={{ zIndex: "1000000" }}
                >
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-first"
                  style={{ zIndex: "1000000" }}
                >
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_type_id")}
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "100000" }}
                >
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "100000" }}
                >
                  <label className="label-name">Floor</label>
                  <Select
                    options={floorList}
                    value={floorList?.find(
                      (option) => option?.value === filterList.floor
                    )}
                    onChange={(e) => handleFilter(e, "floor")}
                    name="floor"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "100000" }}
                >
                  <label className="label-name">Blood Group</label>
                  <Select
                    options={bloodgroupList}
                    value={bloodgroupList?.find(
                      (option) => option?.value === filterList.blood_group_id
                    )}
                    onChange={(e) => handleFilter(e, "blood_group_id")}
                    name="blood_group_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "10000" }}
                >
                  <label className="label-name">Religion</label>
                  <Select
                    options={religionList}
                    value={religionList?.find(
                      (option) => option?.value === filterList.religion_id
                    )}
                    onChange={(e) => handleFilter(e, "religion_id")}
                    name="religion_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "10000" }}
                >
                  <label className="label-name">Caste</label>
                  <Select
                    options={casteList}
                    value={casteList?.find(
                      (option) => option?.value === filterList.caste_id
                    )}
                    onChange={(e) => handleFilter(e, "caste_id")}
                    name="caste_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "10000" }}
                >
                  <label className="label-name">Agent</label>
                  <Select
                    options={agentList}
                    value={agentList?.find(
                      (option) => option?.value === filterList.agent_id
                    )}
                    onChange={(e) => handleFilter(e, "agent_id")}
                    name="agent_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "1000" }}
                >
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div
                  className="field-block dropdown-select-to-second"
                  style={{ zIndex: "1000" }}
                >
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) => handleFilter(e, "designation_id")}
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block" style={{ zIndex: "1000" }}>
                  <label className="label-name">Joining Date</label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_from_date", "date");
                      }}
                      value={filterList?.joining_from_date}
                      max={filterList?.joining_to_date}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_to_date", "date");
                      }}
                      value={filterList?.joining_to_date}
                      min={filterList?.joining_from_date}
                    />
                  </div>
                </div>

                <div className="field-block dropdown-select-to-third z-4">
                  <label className="label-name">Valid Employee</label>
                  <Select
                    options={validEmployee}
                    value={validEmployee?.find(
                      (option) => option?.value === filterList.valid_emp
                    )}
                    onChange={(e) => handleFilter(e, "valid_emp")}
                    name="valid_emp"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-third z-4">
                  <label className="label-name">Employee PF Type</label>
                  <Select
                    options={employeePFTypeList}
                    value={employeePFTypeList?.find(
                      (option) => option?.value === filterList.pf_employee
                    )}
                    onChange={(e) => handleFilter(e, "pf_employee")}
                    name="pf_employee"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block dropdown-select-to-third z-4">
                  <label className="label-name">Approve Type</label>
                  <Select
                    options={approveStatusList}
                    value={approveStatusList?.find(
                      (option) => option?.value === filterList.approve_status
                    )}
                    onChange={(e) => handleFilter(e, "approve_status")}
                    name="approve_status"
                    className=""
                    isSearchable
                  />
                </div>

                <div className="field-block flex justify-center z-5">
                  <div className="add-new-button-block w-[40%] h-max ">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <BulkBonusTable
            columns={columns}
            list={employee}
            bonus={bonusType}
            bulkBonus={bulkBonus}
            setBulkBonus={setbulkBonus}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AddBulkBonus;
