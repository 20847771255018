import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import View from "../../../Assets/Icons/View.svg";
import config from "../../../Services/config.json";
import { GET_API } from "../../../Services/api";
import AdvancePrintVoucher from "./AdvancePrintVoucher";
import { useReactToPrint } from "react-to-print";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";

function AdvanceDetails() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const { state } = useLocation();
  const listDetails = filteredEmployee;
  const [loading, setLoading] = useState(false);

  const [empDetail, setEmpDetail] = useState({});

  const [Advancedetails, setAdvancedetails] = useState([]);

  const Details = [
    {
      label: "Employee ID",
      value: listDetails?.emp_code ? listDetails?.emp_code : "-",
    },
    {
      label: "Employee Name",
      value: listDetails?.first_name + " " + listDetails?.fathers_name,
    },
    {
      label: "Designation",
      value: listDetails?.designation_name,
    },
    {
      label: "Department",
      value: listDetails?.department_name,
    },
    {
      label: "Branch",
      value: listDetails?.branch_name,
    },
    {
      label: "Finance Date",
      value: listDetails?.finance_date,
    },
  ];

  useEffect(() => {
    getAdvanceList();
    getEmployeeDetails();
  }, []);

  const printVoucher = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setTimeout(() => {
        updatePrintStatus();
      }, 2000);
    },
  });

  const updatePrintStatus = async () => {
    try {
      const advanceList = await GET_API(
        "/updateFinancePaymentDetailStatus/" + state.list.id
      );
      if (advanceList?.data?.status === 200) {
        navigate('/expense/advance');
      }
    } catch (error) {
      alertify?.error("Failed to get advance list");
    }
  }

  const getAdvanceList = async () => {
    setLoading(true);
    try {
      const advanceList = await GET_API(
        "/FinancePaymentDetailsList/" + state.list.id
      );
      if (advanceList?.data?.status === 200) {
        setAdvancedetails(advanceList.data.data.FinanceDetails);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get advance list");
    }
  };

  const getEmployeeDetails = async () => {
    setLoading(true);
    try {
      const details = await GET_API(
        "/FinancePaymentDetailsList/" + state.list.id
      );
      if (details?.data?.status === 200) {
        setFilteredEmployee(details.data.data.employeeDetails);
        setLoading(false);
      }
    } catch (error) {
      alertify?.error("Failed to get employee details");
    }
  };

  const advanceDetails = [
    {
      label: "Advance Amount",
      value: Advancedetails?.amount ? Advancedetails?.amount : "-",
    },
    {
      label: "Payment Type",
      value: Advancedetails?.payment_type ? Advancedetails?.payment_type : "-",
    },
    {
      label: "Approved By",
      value: Advancedetails?.approved_by_name
        ? Advancedetails?.approved_by_name
        : "-",
    },
    {
      label: "Paid Status",
      value: listDetails?.Advancedetails === 1 ? "Paid" : "Unpaid",
    },
    {
      label: "Status",
      value:
        Advancedetails?.status === 0
          ? "Approved"
          : Advancedetails?.status === 2
          ? "Rejected"
          : Advancedetails?.status === 3
          ? "Pending"
          : "--",
    },
  ];

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className=" sm-container">
        <div className="bread-crumbs">
          <Link to="/expense/advance" className="in-active">
            Advance List
          </Link>
          <Link>{">"}</Link>
          <Link className="active">Advance Details</Link>
        </div>
        <div className="card-wrapper">
          <h4 className="card-header">Advance Details</h4>
          <div className="search-block" style={{ justifyContent: "end" }}>
            {Advancedetails?.status === 0 ? (
              <button
                type="button"
                className="submit-button"
                onClick={() => printVoucher()}
              >
                <span className="submit-button-label">Print</span>
              </button>
            ) : null}
          </div>

          <div className="advance-details-image">
            <div className="advance-details-image-container">
              <div
                style={{ wordBreak: "break-word" }}
                className="text-[#7B7B7B] text-base font-karla"
              >
                <img
                  src={`${config.path}${listDetails?.profile_image}`}
                  alt=""
                  style={{ height: "120px", width: "120px" }}
                />
              </div>
            </div>
          </div>
          <div className="list-container border-bottom mt-4">
            <div className="advance-section">
              {advanceDetails.map((list, i) => {
                return (
                  <div className="flex " key={i}>
                    <label className="list-label">{list.label}: </label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="advance-section-list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="list-container border-bottom">
            <div className="list-content-block">
              {Details.map((list, i) => {
                return (
                  <div className="list " key={i}>
                    <label className="list-label">{list.label}</label>
                    <div
                      style={{ wordBreak: "break-word" }}
                      className="list-value"
                    >
                      {list.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {Advancedetails?.status === 0 ? (
            <AdvancePrintVoucher
              ref={componentRef}
              printVoucherData={Advancedetails}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AdvanceDetails;
