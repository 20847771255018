import React, { useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Pagination from "./Pagination";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate, useParams } from "react-router-dom";
import SortIcon from "../../Assets/Icons/SortIcon.svg";
import LeaveReportUpdateModal from "./LeaveReportUpdateModal";
import { POST_API } from "./../../Services/api";
import alertify from "alertifyjs";

export default function StickyHeadTable({
  list,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
  allowance,
  termination,
  terminateEmployee,
  activeEdit,
  activeDelete,
  setPageNo,
  pageSize,
  setPageSize,
  lastPage,
  currentPage,
  sorting,
  handleSort,
}) {
  let componentRef = useRef();
  const navigate = useNavigate();
  const today = new Date().toISOString().split('T')[0];
  const [isOpen, setIsOpen] = useState(false);
  const [attendanceDetails, setAttendanceDetails] = useState({
    employee_id: "",
    status: "",
    date: "",
  });
  const [filterParams, setFilterParams] = useState("");
  const [filterList, setfilterList] = useState({
    status: "",
  });
  const [isBtnDisable, setIsBtnDisable] = useState(false);


  const attendanceList = ([
    { label: "Please Select", value: "" },
    { value: 'P', label: 'Present' },
    // { value: 'H', label: 'Half Day' },
    { value: 'L', label: 'Leave' },
    // { value: 'A', label: 'Absent' }
  ]);

  const handleModal = () => {
    setIsOpen(!isOpen);
    setAttendanceDetails({
      employee_id: "",
      status: "",
      date: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAttendanceDetails({ ...attendanceDetails, [name]: value });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleFilter = async (e, name, type) => {
    const value =
      type === "date" || type === "input" ? e?.target?.value : e?.value;
    filterList[name] = value;
    attendanceDetails[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }

    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  
  const handleEditModal = (status, id) => {
    setAttendanceDetails({
      ...attendanceDetails,
      employee_id: id,
      status: status,
      date: today,
    });
    setIsOpen(!isOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsBtnDisable(true);
      const rejoin = await POST_API(`/employee/addAttendanceStatusApproval`, attendanceDetails);
      if (rejoin) {
        alertify.success(rejoin?.data?.message);
        setIsOpen(!isOpen);
        setAttendanceDetails({
          employee_id: "",
          status: "",
          date: "",
        });
        setIsBtnDisable(false);
      }
    } catch (error) {
      setIsBtnDisable(false);
      alertify.error(error?.response?.data?.message);
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  className="table-header"
                  onClick={() => handleSort(column.id, column.sortable)}
                >
                  <div className="flex">
                    {column?.label}
                    {column.sortable && sorting.orderBy === column.id ? (
                      sorting.orderByType === "desc" ? (
                        <img src={SortIcon} alt="sort icon" />
                      ) : (
                        <img src={SortIcon} alt="sort icon" />
                      )
                    ) : (
                      column.sortable && <img src={SortIcon} alt="sort icon" />
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list.map((row, i) => {
                const father_name = row?.father_name ? row.father_name : "";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body !w-[7%]">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        <Link
                          // onClick={() => showDetailsPage(row)}
                          onClick={() => handleEditModal("L", row.employee_id)}
                          style={{ color: "#0284C7" }}
                          className="cursor-pointer text-sky-600 border-b-[1px] border-b-[#0284C7]"
                        >
                          {row.EmployeeCode}
                        </Link>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name + " " + father_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.department_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.designation_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.floor_name}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.attdate}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.clockInTime}
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row.clockOutTime}
                      </TableCell>
                    }
                    {/* {
                      <TableCell className="table-body">
                        {row.clock_out_time}
                      </TableCell>
                    } */}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        
          <LeaveReportUpdateModal
            isOpen={isOpen}
            handleModal={handleModal}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleFilter={handleFilter}
            filterList={filterList}
            attendanceList={attendanceList}
            status={attendanceDetails.status}
            isBtnDisable={isBtnDisable}
          />

      </TableContainer>
      
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
