import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdvanceTable from "../../DataTable/AdvanceTable";
import Search from "../../../Assets/Icons/Search.svg";
import ColorAdd from "../../../Assets/Icons/ColorAdd.svg";
import { GET_API, BASE_PATH, BASE_URL } from "../../../Services/api";
import Spinner from "../../Spinner";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { PrivilegeContext } from "../../../PrivilegeContext";
import Filter from "../../../Assets/Icons/Filter.svg";
import ExcelExport from "../../../Assets/Icons/excel-export.svg";
import Select from "react-select";
import ButtonLoader from "../../ButtonLoader";
import PDFExport from "../../../Assets/Icons/pdf-export.svg";

function AdvanceList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [advanceList, setAdvanceList] = useState([]);
  const [filteredAdvance, setFilteredAdvance] = useState([]);
  const [pagination, setpagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const { access } = useContext(PrivilegeContext);
  const [addAccess, setAddAccess] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [filterParams, setFilterParams] = useState("");
  const [filter, setFilter] = useState(false);
  const [showFilterResultData, setShowFilterResultData] = useState("");

  const [branchList, setBranchList] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [employeetype, setEmployeetype] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isBankFormatLoading, setIsBankFormatLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  // Export Excel and PDF
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);

  const [filterList, setfilterList] = useState({
    employee_id: "",
    branch_id: "",
    employee_type_id: "",
    gender: "",
    department_id: "",
    designation_id: "",
    joining_from_date: "",
    joining_to_date: "",
    finance_date: "",
    payment_type: "",
    status: "",
  });

  useEffect(() => {
    getBranchList();
    getEmployeeType();
    getDepartmentList();
    getDesignationList();
    getEmployeeList();
  }, []);

  useEffect(() => {
    getAdvanceList(currentPage, pageSize, filterParams, searchKey);
  }, [currentPage, pageSize, showFilterResultData]);

  useEffect(() => {
    getAccess();
  }, [access]);

  const getAccess = () => {
    access.map((item) => {
      if (item?.submenu === "advance") {
        setAddAccess(item?.add);
        setShowAccess(item?.view);
      }
      return "";
    });
  };

  const handleFilter = async (e, name, type) => {
    const value = type === "date" ? e?.target?.value : e?.value;
    filterList[name] = value;
    const list = { ...filterList };
    Object.keys(list).forEach((key) => {
      if (list[key] === "") {
        delete list[key];
      }
    });
    let concateURL = "";

    if (isEmptyObject(list)) {
      setFilterParams(concateURL);
      setfilterList({ ...filterList, [name]: value });
    }
    Object.keys(list).forEach((key) => {
      const newURL = `${key}=${list[key]}`;
      concateURL += "&" + newURL;
      setFilterParams(concateURL);
    });
  };

  const showFilterResult = (e) => {
    e?.preventDefault();
    setShowFilterResultData(filterParams);
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const showAdvanceDetails = (listDetail) => {

    if (listDetail.print_status === 0) {
      // Show details if print_status is 0
      navigate("/expense/advance/details", {
        state: {
          list: listDetail,
        },
      });
    } else {
      alertify?.error("This advance voucher already printed You cant able to print again.");
    }

    
  };

  const columns = [
    { id: "s_no", label: "S.No" },
    { id: "employee_code", label: "Employee Code" },
    { id: "employee_name", label: "Employee Name" },
    { id: "paid_status", label: "Paid Status" },
    { id: "created_at", label: "Date" },
    { id: "amount", label: "Amount" },
    { id: "status", label: "Status" },
    { id: "more_icon", label: "" },
  ];

  const isPageNotExit = () => {
    setCurrentPage(1);
    getAdvanceList(1, pageSize, filterParams, searchKey);
  };

  const getAdvanceList = async (page, size, filter, search) => {
    setLoading(true);
    try {
      const advanceList = await GET_API(
        `/FinanceDetailsList/advance?page=${page}&size=${size}${filter}&search=${search}`
      );

      if (advanceList.status === 200) {
        if (
          advanceList?.data?.data?.current_page > advanceList?.data?.data?.last_page
        ) {
          isPageNotExit();
          return;
        }
        setAdvanceList(advanceList?.data?.data?.data);
        setFilteredAdvance(advanceList?.data?.data?.data);
        setLastPage(advanceList?.data?.data?.last_page);
        setTotalAmount(advanceList?.data?.total_amount);
        setLoading(false);
      }
    } catch (error) {
      setAdvanceList([]);
      setFilteredAdvance([]);
      setLastPage("");
      setLoading(false);
      alertify?.error("Failed to get Advance list...");
    }
  };

  const getBranchList = async () => {
    setLoading(true);
    try {
      const branchList = await GET_API("/branchDropdown");
      if (branchList.status === 200) {
        const branch = branchList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });

        branch.unshift({ value: "", label: "Select..." });
        setBranchList(branch);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Branch List");
    }
  };

  const getEmployeeType = async () => {
    setLoading(true);
    try {
      const empType = await GET_API("/employeeTypeDropdown");
      if (empType.status === 200) {
        const type = empType?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        type.unshift({ value: "", label: "Select..." });
        setEmployeetype(type);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee Type List");
    }
  };

  const getDepartmentList = async () => {
    setLoading(true);
    try {
      const departmentList = await GET_API("/departmentDropdown");
      if (departmentList.status === 200) {
        const department = departmentList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        department.unshift({ value: "", label: "Select..." });
        setDepartment(department);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Department List");
    }
  };

  const getDesignationList = async () => {
    setLoading(true);
    try {
      const designationList = await GET_API("/designationDropdown");
      if (designationList.status === 200) {
        const designation = designationList?.data?.data?.map((dep, i) => {
          return { value: dep?.id, label: dep?.name };
        });
        designation.unshift({ value: "", label: "Select..." });
        setDesignation(designation);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Designation List");
    }
  };

  const getEmployeeList = async () => {
    setLoading(true);
    try {
      const empList = await GET_API("/employeeDropdown");
      if (empList.status === 200) {
        const empOption = empList?.data?.data?.map((emp, i) => {
          return {
            value: emp?.id,
            label: `${emp?.first_name} (${emp?.emp_code})`,
          };
        });
        empOption.unshift({ value: "", label: "Select..." });
        setEmployeeName(empOption);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      alertify.error("Failed to Get Employee List");
    }
  };



  const exportData = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-finance/advance?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "advanceReport.xlsx";
        alink.click();
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };

  const handleExportChange = (event) => {
    const value = event.target.value;

    if (value === 'excel') {
      setIsExportLoading(true);
      exportData(); // Call your export function
    } else if (value === 'pdf') {
      setIsExportPdfLoading(true);
      exportAsPDF(); // Call your export function
    }
  };

  const exportAsPDF = async () => {
    const search = searchKey ? `&search=${searchKey}` : "";
    try {
      setIsExportPdfLoading(true);
      const downloadForm = await GET_API(
        `FinanceDetailsAdvanceListPdf?${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = downloadForm?.data?.filename;
        alink.setAttribute("target", "_blank");
        alink.download = "advanceReport.pdf";
        alink.click();
        setIsExportPdfLoading(false);
      }
    } catch (error) {
      setIsExportPdfLoading(false);
    }
  };

  const getBankFormatExport = async () => {
    try {
      setIsBankFormatLoading(true);
      const downloadForm = await GET_API(
        `${BASE_URL}export-advanceBankDetails?page=${currentPage}&size=${pageSize}${filterParams}&search=${searchKey}`
      );
      if (downloadForm?.data?.status === 200) {
        const alink = document.createElement("a");
        alink.href = `${BASE_PATH}${downloadForm?.data?.data}`;
        alink.setAttribute("target", "_blank");
        alink.download = "Bank Format.xlsx";
        alink.click();
        setIsBankFormatLoading(false);
      }
    } catch (error) {
      setIsBankFormatLoading(false);
    }
  };

  const genderList = [
    { label: "Please Select", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const statusList = [
    { label: "Please Select", value: "" },
    { label: "Approved", value: 0 },
    { label: "Pending", value: 3 },
    { label: "Rejected", value: 2 }
  ];

  const paymentType = [
    { value: "", label: "Please Select" },
    { value: "bank", label: "Bank" },
    { value: "cash", label: "Cash" },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className=" bg-[#F7F6F4] grow">
      <div className="sm-container">
        <div className="card-wrapper">
          <div className="card-header">Advance List</div>

          <form
            onSubmit={() =>
              getAdvanceList(currentPage, pageSize, filterParams, searchKey)
            }
          >
            <div className="search-block">
              <div className="search">
                <input
                  type="text"
                  className="search-input-box"
                  placeholder="Search..."
                  autoComplete="off"
                  onChange={(e) => setSearchKey(e?.target?.value)}
                  style={{ outline: "none", border: "none" }}
                  value={searchKey}
                />

                <button type="submit">
                  <img src={Search} alt="" className="cursor-pointer" />
                </button>
              </div>

              <div className="flex gap-3">
                <div
                  className="filter-button"
                  onClick={() => setFilter((prev) => !prev)}
                >
                  <span className="filter-btn-label">Filter</span>
                  <img src={Filter} alt="" />
                </div>

                <div className="export-dropdown dropdown-select-to-second">
                    <select onChange={handleExportChange} defaultValue="" style={{ backgroundColor: 'rgb(217 151 25)', padding: '10px', borderRadius: '5px', color: 'white' }}>
                      <option value="" disabled>Select Export Type</option>
                      <option value="excel">
                        {isExportLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as Excel
                            <img src={ExcelExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                      <option value="pdf">
                        {isExportPdfLoading ? (
                          <ButtonLoader isBtnDisable={true} />
                        ) : (
                          <>
                            Export as PDF
                            <img src={PDFExport} alt="export" style={{ height: '20px', marginLeft: '5px' }} />
                          </>
                        )}
                      </option>
                    </select>
                </div>

                <div className="add-new-button-block">
                  {isBankFormatLoading ? (
                    <ButtonLoader isBtnDisable={isBankFormatLoading} />
                  ) : (
                    <button
                      className="add-new-button"
                      onClick={() => getBankFormatExport()}
                    >
                      <span>Bank Format</span>
                      <img
                        src={ExcelExport}
                        alt="export"
                        style={{ height: "25px" }}
                      />
                    </button>
                  )}
                </div>
                {addAccess && (
                  <>
                    <div className="add-new-button-block">
                      <Link
                        to="/expense/advance/bulkAdd"
                        className="add-new-button"
                      >
                        <span>Bulk Add</span>
                        <img src={ColorAdd} alt="add" />
                      </Link>
                    </div>

                    <div className="add-new-button-block">
                      <Link
                        to="/expense/advance/add"
                        className="add-new-button"
                      >
                        <span>Add New</span>
                        <img src={ColorAdd} alt="add" />
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
          {filter && (
            <form>
              <div className="form ">
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Branch Name</label>
                  <Select
                    options={branchList}
                    value={branchList?.find(
                      (option) => option?.value === filterList.branch_id
                    )}
                    onChange={(e) => handleFilter(e, "branch_id", "select")}
                    name="branch_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Name</label>
                  <Select
                    options={employeeName}
                    value={employeeName?.find(
                      (option) => option?.value === filterList.employee_id
                    )}
                    onChange={(e) => handleFilter(e, "employee_id", "select")}
                    name="employee_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-first">
                  <label className="label-name">Employee Type</label>
                  <Select
                    options={employeetype}
                    value={employeetype?.find(
                      (option) => option?.value === filterList.employee_type_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "employee_type_id", "select")
                    }
                    name="employee_type_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Gender</label>
                  <Select
                    options={genderList}
                    value={genderList?.find(
                      (option) => option?.value === filterList.gender
                    )}
                    onChange={(e) => handleFilter(e, "gender", "select")}
                    name="gender"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Department</label>
                  <Select
                    options={department}
                    value={department?.find(
                      (option) => option?.value === filterList.department_id
                    )}
                    onChange={(e) => handleFilter(e, "department_id", "select")}
                    name="department_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Designation</label>
                  <Select
                    options={designation}
                    value={designation?.find(
                      (option) => option?.value === filterList.designation_id
                    )}
                    onChange={(e) =>
                      handleFilter(e, "designation_id", "select")
                    }
                    name="designation_id"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-third">
                  <label className="label-name">Payment Type</label>
                  <Select
                    options={paymentType}
                    value={paymentType?.find(
                      (option) => option?.value === filterList?.payment_type
                    )}
                    onChange={(e) => handleFilter(e, "payment_type", "select")}
                    name="payment_type"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block dropdown-select-to-second">
                  <label className="label-name">Status</label>
                  <Select
                    options={statusList}
                    value={statusList?.find(
                      (option) => option?.value === filterList.status
                    )}
                    onChange={(e) => handleFilter(e, "status", "select")}
                    name="status"
                    className=""
                    isSearchable
                  />
                </div>
                <div className="field-block">
                  <label className="label-name">Date</label>
                  <div className="flex gap-1 justify-center items-center">
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_from_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_from_date", "date");
                      }}
                      value={filterList?.joining_from_date}
                      max={filterList?.joining_to_date}
                    />
                    <label className="label-name">to</label>
                    <input
                      className="input-field "
                      type="date"
                      autoComplete="off"
                      name="joining_to_date"
                      onChange={(e) => {
                        handleFilter(e, "joining_to_date", "date");
                      }}
                      value={filterList?.joining_to_date}
                      min={filterList?.joining_from_date}
                    />
                  </div>
                </div>
                <div className="field-block">
                  <label className="label-name">Finance Date</label>
                  <input
                    className="input-field "
                    type="date"
                    autoComplete="off"
                    name="finance_date"
                    value={filterList?.finance_date}
                    onChange={(e) => handleFilter(e, "finance_date", "date")}
                  />
                </div>
                <div className="field-block flex justify-center">
                  <div className="add-new-button-block w-[40%] h-max">
                    <button
                      className="add-new-button"
                      onClick={(e) => {
                        showFilterResult(e);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Show Result</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <AdvanceTable
            list={filteredAdvance}
            totalAmount={totalAmount}
            columns={columns}
            showDetails={showAdvanceDetails}
            pagination={pagination}
            setState={setAdvanceList}
            setSearch={setFilteredAdvance}
            setPageNo={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            lastPage={lastPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AdvanceList;
