import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreOption from "../MoreOption";
import Pagination from "../DataTable/Pagination";
import { useNavigate } from "react-router-dom";

export default function StickyHeadTable({
  list,
  activeEdit,
  activeDelete,
  columns,
  showDetails,
  icon,
  multiple,
  deleteRow,
  editPage,
  pageSize,
  setPageSize,
  setPageNo,
  lastPage,
  currentPage,
  enableMoreOption,
}) {
  const navigate = useNavigate();
  const showDetailsPage = (listDetail) => {
    navigate("/expense/details", {
      state: {
        list: listDetail,
      },
    });
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 320 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  className="table-header"
                  key={i}
                  align={column.align}
                >
                  {column?.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan="8"
                  className="table-body td-data-not-found h-[220px]"
                >
                  Data Not Found..!
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className="table-column"
                  >
                    {
                      <TableCell className="table-body">
                        {(currentPage - 1) * pageSize + (i + 1)}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        <a
                          onClick={() => showDetailsPage(row)}
                          style={{ color: "#0284C7" }}
                          className="text-sky-600 border-b-[1px] border-b-[#0284C7]"
                        >
                          {row.EmployeeCode}
                        </a>
                      </TableCell>
                    }
                    {
                      <TableCell className="table-body">
                        {row?.employee_name + " " + row?.fathers_name}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body loan-advance-expence-table">
                        <span>&#8377;{row?.salary}</span>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body loan-advance-expence-table">
                        <span>&#8377;{row?.amount}</span>
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body ">
                        {row?.voucher_status === 1 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Paid
                          </span>
                        ) : (
                          <span
                            className="bg-[#FF5C42]  p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Unpaid
                          </span>
                        )}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.created_at}
                      </TableCell>
                    }

                    {
                      <TableCell className="table-body">
                        {row?.status === 0 ? (
                          <span
                            className="bg-[#3EAB82] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Approved
                          </span>
                        ) : row?.status === 1 ? (
                          <span
                            className="bg-[#EEC9C9] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Pending
                          </span>
                        ) : row?.status === 2 ? (
                          <span
                            className="bg-[#ed452a] p-1"
                            style={{
                              color: "#FFF",
                              borderRadius: "4px",
                            }}
                          >
                            Rejected
                          </span>
                        ) : null}
                      </TableCell>
                    }
                    {row?.status === 0 && (
                      <MoreOption
                        icon={icon}
                        row={row}
                        showDetails={showDetails}
                        activeEdit={activeEdit}
                        activeDelete={activeDelete}
                        multiple={multiple}
                        deleteRow={deleteRow}
                        editPage={editPage}
                        enableMoreOption={enableMoreOption}
                      />
                    )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        lastPage={lastPage}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </Paper>
  );
}
