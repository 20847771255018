import React, { useState } from "react";

import MenuItems from "../MenuItems";
import Toggle from "../../Assets/Icons/Toggle.svg";
import $ from "jquery";
import { Link, useLocation, useParams } from "react-router-dom";

function HomePageMenu() {
  const { id } = useParams();
  const [selectedMenu, setSelectedMenu] = useState("");

  const superAdminMenu = [
    {
      main_title: "Attendance",
      key: "attendance",
      links: [
        {
          id: "attendance_master",
          list: [
            {
              label: "Attendance",
              to: "/attendance",
              active: ["/attendance", `/attendance/details/${id}`],
              key: "attendance",
            },
            {
              label: "Manual Attendance",
              to: "/manualAttendance",
              key: "manual_attendance",
              active: [
                "/manualAttendance",
                "/manualAttendance/add",
                "/manualAttendance/edit",
                `/manualAttendance/details/${id}`,
              ],
            },
            {
              label: "Leave On Duty",
              to: "/leaveonduty",
              key: "leave_onduty",
              active: [
                "/leaveonduty",
                "/leaveonduty/add",
                "/leaveonduty/edit",
                `/leaveonduty/details/${id}`,
              ],
            },
            {
              label: "Employee Fine Details",
              to: "/fineList",
              key: "employee_fine_details",
              active: ["/fineList", "/fineList/add"],
            },
            {
              label: "Late Fine",
              to: "/latefineList",
              key: "employee_fine_details",
              active: ["/latefineList", "/latefineList/add"],
            },
          ],
        },
      ],
    },

    {
      main_title: "Employee Management",
      key: "employee_management",
      links: [
        {
          id: "employee_managment",
          list: [
            {
              label: "List",
              to: "/employee/list",
              active: [
                "/employee/list",
                `/employee/edit/${id}`,
                `/employee/details/${id}`,
                `/employee/allowance/${id}`,
                `/employee/details`,
                `/employee/allowance/create/${id}`,
              ],
              key: "list",
            },
            {
              label: "Add",
              to: "/employee/add",
              active: ["/employee/add"],
              key: "add",
            },
            {
              label: "Employee Rejoin",
              to: "/employee/rejoin",
              active: ["/employee/rejoin"],
              key: "employee_rejoin",
            },
          ],
        },
      ],
    },

    {
      main_title: "Loan & Expenses",
      key: "loan_and_expenses",
      links: [
        {
          id: "loan& expenses",
          list: [
            {
              label: "Advance",
              to: "/expense/advance",
              active: [
                "/expense/advance",
                "/expense/advance/add",
                "/expense/advance/bulkAdd",
                "/expense/advance/details",
              ],
              key: "advance",
            },
            {
              label: "Expenses",
              to: "/expense",
              active: ["/expense", "/expense/add", "/expense/details"],
              key: "expenses",
            },
            {
              label: "Loan",
              to: "/expense/loan",
              active: [
                "/expense/loan",
                "/expense/loan/add",
                "/expense/loan/details",
                "/expense/loan/deduction/add",
                "/expense/loan/update",
              ],
              key: "loan",
            },
            {
              label: "Agent Commission",
              to: "/expense/agentcommission",
              active: [
                "/expense/agentcommission",
                "/expense/agentcommission/add",
                "/expense/agentcommission/edit",
                `/expense/agentcommission/details/${id}`,
              ],
              key: "agent_commission",
            },
            {
              label: "Bonus",
              to: "/expense/bonus",
              active: [
                "/expense/bonus",
                "/expense/bonus/add",
                "/expense/bonus/bulkAdd",
                "/expense/bonus/details",
              ],
              key: "bonus",
            },
          ],
        },
      ],
    },

    {
      main_title: "Payroll",
      key: "payroll",
      links: [
        {
          id: "payroll",
          list: [
            {
              label: "Generate Payslip",
              to: "/payroll/payslip",
              active: ["/payroll/payslip", `/payroll/payslip/details/${id}`],
              key: "generate_payslip",
            },
            {
              label: "Manual PF",
              to: "/payroll/manualpf",
              active: ["/payroll/manualpf"],
              key: "manual_PF",
            },
            {
              label: "Bank Salary",
              to: "/payroll/banksalary",
              active: ["/payroll/banksalary", `/payroll/banksalary`],
              key: "bank_salary",
            },
            {
              label: "Payslip Details",
              to: "/payroll/payslipdetails",
              active: [
                "/payroll/payslipdetails",
                `/payroll/payslipdetails/details/${id}`,
              ],
              key: "payslip_details",
            },
            {
              label: "Salary Details",
              to: "/payroll/salaryDetails",
              active: [
                "/payroll/salaryDetails",
                `/payroll/salaryDetails/${id}`,
              ],
              key: "salary_details",
            },
          ],
        },
      ],
    },

    {
      main_title: "Reports",
      key: "reports",
      links: [
        {
          id: "report",
          list: [
            {
              label: "Employee Report",
              to: "/report/employee",
              active: [`/report/employee/details/${id}`, "/report/employee"],
              key: "employee_report",
            },
            {
              label: "Employee Master Report",
              to: "/report/employeemaster",
              active: ["/report/employeemaster"],
              key: "employee_master_report",
            },
            {
              label: "Termination Report",
              to: "/report/termination",
              active: [
                "/report/termination",
                `/report/termination/details/${id}`,
              ],
              key: "termination_report",
            },
            {
              label: "Attendance List Report",
              to: "/report/attendance",
              active: ["/report/attendance"],
              key: "attendance_list_report",
            },
            {
              label: "Leave Report",
              to: "/report/leave",
              active: ["/report/leave", `/report/leave/details/${id}`],
              key: "leave_report",
            },
            {
              label: "Long Leave Report",
              to: "/report/long/leave",
              active: [
                "/report/long/leave",
                `/report/long/leave/details/${id}`,
              ],
              key: "longleave_report",
            },
            {
              label: "Loan Ledger Report",
              to: "/report/loan/ledger",
              active: [
                "/report/loan/ledger",
                `/report/loan/ledger/details/${id}`,
              ],
              key: "loan_ledger",
            },
            {
              label: "Absent Report",
              to: "/report/absent",
              active: ["/report/absent", `/report/absent/details/${id}`],
              key: "absent_report",
            },

            {
              label: "Exit Report",
              to: "/report/exit",
              active: ["/report/exit", `/report/exit/details/${id}`],
              key: "exit_report",
            },
            {
              label: "On Duty Report",
              to: "/report/onduty",
              active: ["/report/onduty"],
              key: "on_duty_report",
            },
            {
              label: "Late Fine Report",
              to: "/report/latefine",
              active: ["/report/latefine"],
              key: "late_fine_report",
            },
            {
              label: "Advance Report",
              to: "/report/advance",
              active: ["/report/advance"],
              key: "advance_report",
            },
            {
              label: "Loan Report",
              to: "/report/loan",
              active: ["/report/loan"],
              key: "loan_report",
            },

            {
              label: "Expense Report",
              to: "/report/expense",
              active: ["/report/expense"],
              key: "expense_report",
            },
            {
              label: "Cost Report",
              to: "/report/cost",
              active: ["/report/cost"],
              key: "cost_report",
            },
            {
              label: "Movement Report",
              to: "/report/movement",
              active: ["/report/movement"],
              key: "movement_report",
            },
            {
              label: "Part Time Employee Report",
              to: "/report/parttimeEmployee",
              active: ["/report/parttimeEmployee"],
              key: "part_time_employee_report",
            },
            {
              label: "Food Allowance Report",
              to: "/report/foodallowance",
              active: ["/report/foodallowance"],
              key: "food_allowance_report",
            },
            {
              label: "Early Exit Report",
              to: "/report/earlyExit",
              active: ["/report/earlyExit", `/report/earlyExit/details/${id}`],
              key: "early_exit_report",
            },
            {
              label: "Late Entry Report",
              to: "/report/lateEntry",
              active: ["/report/lateEntry"],
              key: "late_entry_report",
            },
            {
              label: "Invalid Attendance Report",
              to: "/report/invalidAttendance",
              active: ["/report/invalidAttendance"],
              key: "invalid_report",
            },
            {
              label: "Branch Wise Report",
              to: "/report/branchwise",
              active: ["/report/branchwise"],
              key: "branch_wise_report",
            },
            {
              label: "Floor Wise Report",
              to: "/report/floorwise",
              active: ["/report/floorwise"],
              key: "floor_wise_report",
            },
            {
              label: "Consolidate Attendance Report",
              to: "/report/consolidate",
              active: ["/report/consolidate"],
              key: "consolidate_report",
            },
            {
              label: "Agent comm Creation Report",
              to: "/report/agentCommCreation",
              active: ["/report/agentCommCreation"],
              key: "agent_commission_creation_report",
            },
            {
              label: "Agent comm Actual Report",
              to: "/report/agentCommActual",
              active: ["/report/agentCommActual"],
              key: "agent_commission_actual_report",
            },
            {
              label: "Payroll Report",
              to: "/report/payroll",
              active: [`/report/payroll/details/${id}`, "/report/payroll"],
              key: "payroll_report",
            },
            {
              label: "Terminated Employee Loan Report",
              to: "/report/terminated-employee-loan-report",
              active: ["/report/terminated-employee-loan-report"],
              key: "terminated_employee_loan_report",
            },
          ],
        },
      ],
    },

    {
      main_title: "Approve",
      key: "approve",
      links: [
        {
          id: "approve",
          list: [
            {
              label: "Employee",
              to: "/approve/employee",
              active: ["/approve/employee"],
              key: "approve_employee",
            },
            {
              label: "Masters",
              to: "/approve/masters",
              active: ["/approve/masters", `/approve/masters/log/${id}`],
              key: "approve_master",
            },
            {
              label: "Leave",
              to: "/approve/leave",
              active: ["/approve/leave"],
              key: "approve_leave",
            },
            {
              label: "Late Entry",
              to: "/approve/lateentry",
              active: ["/approve/lateentry"],
              key: "approve_late_entry",
            },
            {
              label: "Early Exit",
              to: "/approve/earlyExit",
              active: [
                "/approve/earlyExit",
                `/approve/earlyExit/details/${id}`,
              ],
              key: "approve_early_exit",
            },
            {
              label: "Finance",
              to: "/approve/finance",
              active: ["/approve/finance"],
              key: "approve_finance",
            },
            {
              label: "Bonus",
              to: "/approve/bonus",
              active: ["/approve/bonus"],
              key: "approve_bonus",
            },
            {
              label: "Expense",
              to: "/approve/expense",
              active: ["/approve/expense"],
              key: "approve_expense",
            },
            {
              label: "Manual Attendance",
              to: "/approve/manualAttendance",
              active: ["/approve/manualAttendance"],
              key: "approve_manual_attendance",
            },
            {
              label: "Attendance Status",
              to: "/approve/attendanceStatus",
              active: ["/approve/attendanceStatus"],
              key: "approve_attendance_status",
            },
            {
              label: "User",
              to: "/approve/user",
              active: ["/approve/user"],
              key: "approve_user",
            },
            {
              label: "Loan Skip and Carry",
              to: "/approve/loan-skip-carry",
              active: ["/approve/loan-skip-carry"],
              key: "approve_loan_skip_carry",
            },
          ],
        },
      ],
    },

    {
      main_title: "Voucher",
      key: "voucher",
      links: [
        {
          id: "voucher",
          list: [
            {
              label: "Food Allowance",
              to: "/voucher/foodAllowance",
              active: ["/voucher/foodAllowance"],
              key: "food_allowance",
            },
          ],
        },
      ],
    },

    {
      main_title: "Detection",
      key: "detection",
      to: "detection",
    },
  ];

  const handleToggle = () => {
    $("#toggle-menu").animate({
      width: "toggle",
    });
  };

  let location = useLocation();
  const currentLocation = location?.pathname.split("/")[1];
  return (
    <div className="w-1/5 overflow-x-hidden shadow-md" id="toggle-menu">
      <div className=" pl-[8px] pr-[8px] pt-[12px]">
        <div className="flex justify-end">
          <img
            src={Toggle}
            alt=""
            className="cursor-pointer"
            onClick={handleToggle}
          />
        </div>
        <Link
          to="dashboard"
          className={`font-montserrat text-base whitespace-nowrap font-medium ${
            currentLocation === "dashboard"
              ? "text-text-dark"
              : "text-text-light"
          }`}
        >
          Dashboard
        </Link>
      </div>
      <div className="flex flex-col gap-1">
        {superAdminMenu?.map((nav, i) => {
          return (
            <React.Fragment key={i}>
              <MenuItems
                selected={selectedMenu === nav.main_title}
                menuName={nav?.key}
                title={nav?.main_title}
                to={nav?.to}
                links={nav?.links}
                handleMenuClick={setSelectedMenu}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default HomePageMenu;
